<template>
  <main :class="type ? 'py-3' : ''">
    <span @click="closeEvent" class="absolute top-4 right-4 cursor-pointer">
      <img src="@/assets/icons/close.svg" alt="" />
    </span>

    <h4 class="text-xl md:text-3xl text-center font-medium pt-5 pb-2">
      {{ type ? "Add" : "Edit" }} Branch
    </h4>

    <hr class="border border-newLightgrey my-4 mx-8" />

    <form
      @submit.prevent="submit"
      autocomplete="on"
      class="flex flex-col gap-y-4"
      :class="type ? 'px-8' : ''"
    >
      <easiTextInput
        placeholder="Branch Name"
        type="text"
        name="branchName"
        class="mt-1 mb-5 capitalize"
        required
        v-model="args.name"
        :error="errorRules.name"
      ></easiTextInput>

      <easiTextInput
        placeholder="Enter Location"
        type="text"
        name="location"
        class="mt-1 mb-5"
        v-model="args.address"
        :error="errorRules.address"
        autocom
      ></easiTextInput>

      <easiEmployeeBonus
        v-if="allEmployees && allEmployees.length"
        @update="branchHead = $event"
        :value="branchHead"
        :options="createEmpList()"
        :clearAll="clear"
        :single="true"
        :placeHolder="'Select Head of Branch'"
        class="mb-2"
      />

      <hr class="border border-newLightgrey mt-0 mb-2" />

      <label for="employees" class="text-dark-800 mt-1 text-sm font-medium"
        >Add Employee (s)</label
      >
      <easiEmployeeBonus
        @update="employees = $event"
        :value="employees"
        :options="createEmpList()"
        :clearAll="clear"
        class="mb-3"
      />
      <hr class="border border-newLightgrey mt-0 mb-5" />

      <!-- <hr v-if="type" class="border border-newLightgrey mt-5 mb-7" /> -->
      <div
        v-if="type"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          type="button"
          @click="save"
          variant="outlined"
          color="primary"
          class="w-80 md:w-48"
          :loading="loading.save"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="w-80 md:w-48"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>

      <div
        v-else
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="closeEvent"
          class="md:w-48 w-full font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <div class="w-full md:w-auto">
          <easiButton
            type="submit"
            :loading="loading.edit"
            class="w-full md:w-48"
          >
            Update
          </easiButton>
        </div>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Branch {{ type ? "Created" : "Updated" }} successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
// import UploadComponent from "@/components/global/UploadComponent";
// import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
// import NigerianStates from "@/utils/states";
// import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { getAllEmployees, getAllBranches } = storeToRefs(store);

const props = defineProps({
  type: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close"]);

const { processDate, validatePhone, validateEmail, resetObj, log } =
  helperFunctions;

const visibleRef = ref(false);
const clear = ref(false);
const branchHead = ref([]);
const employees = ref([]);
const imgsRef = ref("");
const allEmployees = computed(() => getAllEmployees.value.data);

const branch = ref(true);

const updateSuccess = ref(false);
const deleteWarning = ref(false);
const addBank = ref(false);
const show = ref(false);
const loading = ref({
  add: false,
  edit: false,
  save: false,
});
const isEdit = ref(false);
// const companyData = computed(() => store.getCompanyDetails);
const errorRules = ref({
  name: "",
  employees: "",
  branchHead: "",
  address: "",
});

const imgLink = ref("@/assets/img/cert.png");
// const industryData = IndustryList.map((el) => el.name);

let args = reactive({
  name: "",
  address: "",
  branchHead: [],
  employees: [],
});

const profileArgsFromStore = computed(() => store.profileData);

const argsArray = ref([]);

const branchId = window.localStorage.getItem("branchId");

function getBranchId(id) {
  let branch = [];
  if (branchId) {
    branch = getAllBranches.value.data.filter((el) => el._id === id);
  }
  return branch;
}

const editBranch = getBranchId(branchId);

onMounted(() => {
  assignValues();
  store.$patch({
    profileArr: [],
  });
});

function assignValues() {
  if (!props.type) {
    if (editBranch.length) {
      const branch = editBranch[0];
      (args.name = branch.name),
        (args.employees = getEmpIds(branch.employees)),
        (args.branchHead = [branch.branchHead._id]),
        (employees.value = getEmpIds(branch.employees)),
        (branchHead.value = [branch.branchHead._id]),
        (args.address = branch.address),
        (args._id = branch._id);
    }
  }

  function getEmpIds(arr) {
    return arr.map((el) => el._id);
  }
}
assignValues();

function closeEvent() {
  emit("close");
}

function addMore(arg) {
  arg.branchHead = branchHead.value[0]; // convert the branch head array to a string
  args.employees = employees.value.map((emp) => emp); //map to ceate a new array with a different reference
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
  branchHead.value = branchHead.value.splice(0);
  employees.value = employees.value.splice(0);
  clear.value = false;
}

async function save() {
  addMore(args);
  await createBranch(false);
}

// const statesData = computed(() => {
//   let stateArray = [];
//   stateArray = NigerianStates.map((state) => {
//     return {
//       label: state,
//       value: state,
//     };
//   });
//   return stateArray;
// });

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

const submit = () => {
  if (props.type) {
    createBranch(true);
  } else {
    updateBranch();
  }
};

const createBranch = async (final) => {
  if (final) {
    loading.value.add = true;
    addMore(args);
  } else {
    loading.value.save = true;
  }

  try {
    let res = await mutate({
      endpoint: "CreateBranch",
      data: {
        input: profileArgsFromStore.value,
      },
      service: "SETTINGS",
    });

    loading.value.add = false;
    console.log(res);
    // log(res);

    args = resetObj(args, "args");
    Object.assign(args, resetObj(args, "args"));
    store.$patch({
      profileArr: [],
    });

    await queryBranch();
    if (final) {
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
        closeEvent();
        // window.location.reload();
      }, 500);
    } else {
      toast.success("Details saved!");
    }
  } catch (e) {
    loading.value.add = false;
    console.log(e);
    args = resetObj(args, "args");
    store.$patch({
      profileArr: [],
    });
  } finally {
    loading.value.add = false;
    loading.value.save = false;
    loading.value.edit = false;
  }
};

const updateBranch = async () => {
  loading.value.edit = true;

  const payload = {
    address: args.address,
    branchHead: branchHead.value[0],
    employees: employees.value,
    name: args.name,
  };

  await mutate({
    endpoint: "UpdateBranch",
    data: { branchId: args._id, input: payload },

    service: "SETTINGS",
  })
    .then((res) => {
      loading.value.edit = false;
      updateSuccess.value = true;
      // log(res);

      args = resetObj(args, "args");
      Object.assign(args, resetObj(args, "args"));
      store.$patch({
        profileArr: [],
      });

      queryBranch();
      setTimeout(() => {
        updateSuccess.value = false;
        closeEvent();
        // window.location.reload();
      }, 500);
    })
    .catch((e) => {
      loading.value.edit = false;
      console.log(e);
      args = resetObj(args, "args");
      store.$patch({
        profileArr: [],
      });
    });
};
// const getAvatar = (employee) => {
//   let name = "",
//     color;
//   // Get color
//   const colors = [
//     "#D32F2F",
//     "#189F00",
//     "#0A244F",
//     "#E4572E",
//     "#184EA8",
//     "#F39C36",
//     "#4f46e5",
//   ];

//   const randomNumber = Math.floor(Math.random() * 5);
//   color = colors[randomNumber + 1];

//   // Get Shortend Name
//   employee
//     ? (name = `${employee.profile && employee.profile.firstName.charAt(0)}${
//         employee.profile && employee.profile.lastName.charAt(0)
//       }`)
//     : "";

//   return {
//     color,
//     name,
//   };
// };

const queryBranch = async () => {
  try {
    await query({
      endpoint: "ListBranches",
      service: "SETTINGS",
      storeKey: "listBranches",
    });
    console.log("Branches");
  } catch (err) {
    console.log(err);
  }
};

const onPreview = (img) => {
  imgsRef.value = img;
  visibleRef.value = true;
};

const onHide = () => (visibleRef.value = false);

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};
</script>

<style></style>
